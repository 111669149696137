<template>
    <div class="uk-grid-small" uk-grid>
        <div class="uk-width-1-1">
            <div class="uk-padding-small uk-border-rounded uk-padding-remove-top" style="background: #0275D8;">
                <label for="admin_pic" class="uk-form-label" style="color: #FFFFFF">PIC</label>
                <multiselect
                    id="admin_pic"
                    name="admin_pic"
                    track-by="_id"
                    placeholder="Administrator Job Vacancy (WhatsApp)"
                    label="name"
                    :multiple="false"
                    :options="adminJobVacancyOptions"
                    :options-limit="1000"
                    v-model="formData.admin_job_vacancy_id"
                ></multiselect>
            </div>
        </div>
        <div class="uk-width-1-1">
            <label for="role_id" class="uk-form-label">Job role</label>
            <select
                id="role_id"
                name="role_id"
                class="uk-select uk-border-rounded"
                :class="{'uk-form-danger': errors.has('role_id')}"
                v-model="formData.role_id"
                v-validate="'required'"
                required
            >
                <option value="" disabled selected>Select Job Role</option>
                <option
                    v-for="(item, index) in roleOptions"
                    :key="index"
                    :value="item._id"
                >{{ item.name }}</option>
            </select>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('role_id')">{{ errors.first('role_id') }}</span>
        </div>
        <div class="uk-width-1-1">
            <label for="contract" class="uk-form-label">Related Job Preference(s) <span class="uk-text-danger">*</span></label>
            <multiselect
                id="job_preference"
                name="job_preference"
                track-by="_id"
                placeholder="Select job preference"
                label="name"
                :multiple="false"
                :options="jobPreferenceOptions"
                :options-limit="10000"
                v-model="formData.job_preference_id"
                v-validate="'required'"
                required
            ></multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('job_preference')">{{ errors.first('job_preference') }}</span>
        </div>
        <div class="uk-width-1-1">
            <label for="title" class="uk-form-label">Vacancy Title <span class="uk-text-danger">*</span></label>
            <input
                id="title"
                name="title"
                type="text"
                minlength="10"
                maxlength="200"
                class="uk-input uk-border-rounded"
                :class="{'uk-form-danger': errors.has('title')}"
                v-model="formData.title"
                v-validate="'required'"
                required
            >
            <div class="uk-flex" :class="[errors.has('title') ? 'uk-flex-between' : 'uk-flex-right']">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('title')">{{ errors.first('title') }}</span>
                <span>{{ vacancyTitleLength }}/200</span>
            </div>
        </div>
        <div class="uk-width-1-1">
            <label for="company" class="uk-form-label">Company</label>
            <input
                id="company"
                type="text"
                class="uk-input uk-border-rounded"
                :value="company.name || ''"
                disabled
            >
        </div>
        <div class="uk-width-1-1">
            <label for="office" class="uk-form-label">Office <span class="uk-text-danger">*</span></label>
            <div uk-grid class="uk-padding-small uk-grid-collapse" style="border: 1px solid #CCC;">
                <div class="uk-width-expand">Office</div>
                <div class="uk-width-1-3">Base Salary</div>
            </div>
            <section style="border: 1px solid #CCC;">
                <div
                    v-for="(office, oKey) in offices"
                    :key="oKey"
                    uk-grid
                    class="uk-grid-collapse"
                    style="padding: 5px 10px 5px 10px"
                    :style="{ backgroundColor: (oKey % 2 != 0) ? '#CCC' : '' }"
                >
                    <div class="uk-width-expand">
                        <label>
                            <input
                                class="uk-checkbox"
                                type="checkbox"
                                style="margin-right: 10px"
                                @change="parsingOffice(office._id)"
                                :checked="formData.initiation_ids.includes(office._id)"
                            >
                            {{ office.office_name }}
                        </label>
                    </div>
                    <div class="uk-width-1-3">Rp{{ formatter.thousandSeparator(office.fee_normal) }}</div>
                </div>
            </section>
            <span class="uk-text-small uk-text-danger" v-show="$props.formData.initiation_ids.length < 1">The office is required</span>
        </div>
        <div class="uk-width-1-1">
            <label for="jobDescription" class="uk-form-label">Job Descriptions <span class="uk-text-danger">*</span></label>
            <textarea
                id="jobDescription"
                name="desc"
                rows="5"
                minlength="70"
                maxlength="320"
                class="uk-textarea uk-border-rounded"
                :class="{'uk-form-danger': errors.has('desc')}"
                v-model="formData.job_description"
                v-validate="'required'"
                required
            ></textarea>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('desc')">{{ errors.first('desc') }}</span>
        </div>
        <div class="uk-width-1-1">
            <label for="vacancyStatusFilter" class="uk-form-label">Vacancy Status Filter <span class="uk-text-danger">*</span></label>
            <select
                id="vacancyStatusFilter"
                name="vacancy_status_filter"
                class="uk-select uk-border-rounded"
                :class="{'uk-form-danger': errors.has('vacancy_status_filter')}"
                v-model="formData.job_type_id"
                v-validate="'required'"
                required
            >
                <option value="" disabled selected>Select Vacancy Status Filter</option>
                <option
                    v-for="(item, index) in jobTypeOptions"
                    :key="index"
                    :value="item._id"
                >{{ item.name || '-' }}</option>
            </select>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('vacancy_status_filter')">{{ errors.first('vacancy_status_filter') }}</span>
        </div>
        <div class="uk-width-1-1">
            <label for="contract" class="uk-form-label">Contract <span class="uk-text-danger">*</span></label>
            <multiselect
                id="contract"
                name="contract"
                track-by="_id"
                placeholder="Select Contract"
                label="title"
                :multiple="true"
                :options="contractOptions"
                v-model="formData.job_contracts"
                v-validate="'required'"
                required
            ></multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('contract')">{{ errors.first('contract') }}</span>
        </div>
        <div class="uk-width-1-1">
            <label for="benefits" class="uk-form-label">Benefits (Optional)</label>
            <input
                id="benefits"
                name="Benefit"
                type="text"
                class="uk-input uk-border-rounded"
                v-model="formData.benefits"
            >
        </div>
        <div class="uk-width-1-1">
          <label for="job_start" class="uk-form-label">Start Date <span class="uk-text-danger">*</span></label>
          <div class="uk-width-1-1 uk-inline">
            <datetime
                id="job_start"
                name="job_start"
                :input-class="errors.has('job_start') ? 'uk-form-danger uk-input uk-border-rounded' : 'uk-input uk-border-rounded'"
                type="date"
                v-model="formData.job_start"
                v-validate="'required'"
                required
            ></datetime>
            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
          </div>
          <span class="uk-text-small uk-text-danger" v-show="errors.has('job_start')">{{ errors.first('job_start') }}</span>
        </div>
        <div class="uk-width-1-1">
            <label :for="'workHourStart' && 'workHourEnd'" class="uk-form-label">Work Hours (Optional)</label>
            <div class="uk-width-1-1 uk-grid-small uk-padding-remove uk-margin-remove" uk-grid>
                <div class="uk-width-1-3 uk-padding-remove uk-margin-remove">
                    <div class="uk-inline uk-width-expand">
                        <datetime
                            id="workHourStart"
                            name="work_hour_start"
                            input-class="uk-input uk-border-rounded"
                            type="time"
                            v-model="formData.working_hour_start"
                            :max-datetime="formData.working_hour_end"
                            placeholder="HH:MM"
                        ></datetime>
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: clock"></span>
                    </div>
                </div>
                <div class="uk-width-1-3 uk-padding-remove uk-margin-remove uk-flex uk-flex-middle uk-flex-center">
                    <span class="uk-text-bold"> - </span>
                </div>
                <div class="uk-width-1-3 uk-padding-remove uk-margin-remove">
                    <div class="uk-inline uk-width-expand">
                        <datetime
                            id="workHourEnd"
                            name="working_hour_end"
                            input-class="uk-input uk-border-rounded"
                            type="time"
                            v-model="formData.working_hour_end"
                            :min-datetime="formData.working_hour_start"
                            :disabled="!formData.working_hour_start"
                            placeholder="HH:MM"
                        ></datetime>
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: clock"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-width-1-2">
            <label for="gender" class="uk-form-label">Gender <span class="uk-text-danger">*</span></label>
            <select
                id="gender"
                name="gender"
                class="uk-select uk-border-rounded"
                v-model="formData.gender"
            >
                <option
                    v-for="(item, index) in genderOptions"
                    :key="index"
                    :value="item.value"
                >{{ item.label || '-' }}</option>
            </select>
        </div>
        <div class="uk-width-1-2">
            <label class="uk-form-label" :for="'minAge' && 'maxAge'"><input class="uk-checkbox" type="checkbox" v-model="ageFilter.needed"> Age Limit</label>
            <div v-if="ageFilter.needed" class="uk-width-1-1 uk-grid-small uk-padding-remove uk-margin-remove" uk-grid>
                <div class="uk-width-1-3 uk-padding-remove uk-margin-remove">
                    <input
                        id="minAge"
                        name="age_min"
                        type="number"
                        class="uk-input uk-border-rounded"
                        :class="{'uk-form-danger': errors.has('age_min')}"
                        min=16
                        v-model="ageFilter.min"
                        v-validate="'required'"
                        required
                    >
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('age_min')">{{ errors.first('age_min') }}</span>
                </div>
                <div class="uk-width-1-3 uk-padding-remove uk-margin-remove uk-flex uk-flex-middle uk-flex-center">
                    <span class="uk-text-bold"> - </span>
                </div>
                <div class="uk-width-1-3 uk-padding-remove uk-margin-remove">
                    <input
                        id="maxAge"
                        name="age_max"
                        type="number"
                        class="uk-input uk-border-rounded"
                        :class="{'uk-form-danger': errors.has('age_max')}"
                        :min="ageFilter.min"
                        max=61
                        v-model="ageFilter.max"
                        v-validate="'required'"
                        required
                    >
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('age_max')">{{ errors.first('age_max') }}</span>
                </div>
            </div>
        </div>
        <div class="uk-width-1-1">
            <label for="minEducation" class="uk-form-label">Minimum Education <span class="uk-text-danger">*</span></label>
            <select
                id="education"
                name="education"
                class="uk-select uk-border-rounded"
                :class="{'uk-form-danger': errors.has('education')}"
                v-model="formData.education_level_id"
                v-validate="'required'"
                required
            >
                <option value="" disabled selected>Pilih Pendidikan Minimal</option>
                <option
                    v-for="(item, index) in educationLevelOptions"
                    :key="index"
                    :value="item._id"
                >{{ item.name || '-' }}</option>
            </select>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('education')">{{ errors.first('education') }}</span>
        </div>
        <div class="uk-width-1-1">
            <label for="skillNeeded" class="uk-form-label">Required Skills <span class="uk-text-danger">*</span></label>
            <input
                id="skillNeeded"
                name="skills"
                type="text"
                class="uk-input uk-border-rounded"
                :class="{'uk-form-danger': errors.has('skills')}"
                v-model="formData.skill_needed"
                v-validate="'required'"
                required
            >
            <span class="uk-text-small uk-text-danger" v-show="errors.has('skills')">{{ errors.first('skills') }}</span>
        </div>
        <div class="uk-width-1-1">
            <label class="uk-form-label" for="maxAge"><input class="uk-checkbox" type="checkbox" v-model="formData.is_experience_needed"> Butuh Pengalaman kerja</label>
        </div>
        <div class="uk-width-1-1">
            <label for="requiredDocs" class="uk-form-label">Required Docs</label>
            <multiselect
                id="requiredDocs"
                name="Required Docs"
                placeholder="Select Docs to be Required"
                label="label"
                track-by="value"
                :multiple="true"
                :options="docOptions"
                v-model="formData.required_docs"
            >
              <template v-slot:tag="{ option }">
                    <span v-if="option.label === 'KTP'">
                      <i class="multiselect__tag" style="padding-right: 12px; font-style: normal">{{ option.label }}</i>
                    </span>
              </template>
            </multiselect>
        </div>
        <div class="uk-width-1-1">
            <label for="additionalDocs" class="uk-form-label">Additional Docs</label>
            <multiselect
                id="additionalDocs"
                name="Additional Docs"
                placeholder="Select Additional Docs"
                label="label"
                track-by="value"
                :multiple="true"
                :options="additionalDocOptions"
                v-model="formData.additional_docs"
            ></multiselect>
        </div>
        <div class="uk-width-1-1">
            <label for="requiredTest" class="uk-form-label">Required Test</label>
            <multiselect
                id="requiredTest"
                name="category"
                v-model="formData.required_tests"
                placeholder="Select Category"
                label="name"
                track-by="_id"
                :multiple="true"
                :options="testCategoryOptions"
            ></multiselect>
        </div>
        <template v-if="formData.required_tests.length > 0">
            <div
                v-for="(category, index) in formData.required_tests"
                :key="index"
                class="uk-width-1-1"
            >
                <label
                    for="minimumScore"
                    class="uk-form-label"
                    :key="category.name"
                >{{ category.name || '-' }} Minimum Score <span class="uk-text-danger">*</span></label>

                <input
                    :key="category._id"
                    id="minimumScore"
                    name="minimum_score"
                    class="uk-input uk-border-rounded"
                    type="number"
                    v-model="category.minimum_score"
                    max="100"
                    min="0"
                    required
                >
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import { Datetime } from 'vue-datetime';
import Multiselect from 'vue-multiselect';
import formatter from "@/utils/formatter";
import { sortString } from '@/utils/helper';
moment.locale('id');

export default {
    props: {
        jobTypeOptions: {
            type: Array,
            required: true
        },
        contractOptions: {
            type: Array,
            required: true
        },
        jobPreferenceOptions: {
            type: Array,
            required: true
        },
        adminJobVacancyOptions: {
            type: Array,
            required: true
        },
        ageFilter: {
            type: Object,
            required: true
        },
        genderOptions: {
            type: Array,
            required: true
        },
        educationLevelOptions: {
            type: Array,
            required: true
        },
        docOptions: {
            type: Array,
            required: true
        },
        additionalDocOptions: {
            type: Array,
            required: true
        },
        testCategoryOptions: {
            type: Array,
            required: true
        },
        formData: {
            type: Object,
            required: true,
        },
        roleOptions: {
            type: Array,
            required: true,
        },
        company: {
            type: Object,
            required: true,
        }
    },
    components: {
        Multiselect,
        Datetime
    },
    data(){
        return {
            isLoading: false,
            offices: [],
            formatter,
        };
    },
    computed: {
        vacancyTitleLength() {
            return this.formData?.title?.length || 0;
        }
    },
    mounted() {
        this.validate();
    },
    watch: {
        "$props.formData": {
            deep: true,
            handler() {
                this.validate();
            },
        },
        "$props.formData.role_id"() {
            if (this.$props.formData.role_id) {
                this.getOffices();
            } else {
                this.offices = [];
            }
        }
    },
    methods: {
        ...mapActions({
            getOfficeByRole: 'initiation/getOfficeByRole',
        }),
        async getOffices() {
            this.offices = [];
            this.$props.formData.initiation_ids = [];
            const response = await this.getOfficeByRole(this.formData.role_id);

            if (response && response.length > 0) {
                this.offices = sortString(response, 'office_name', 'asc');
                this.$props.formData.initiation_ids = this.offices.map(d => d._id);
            }
        },
        async validate() {
            const validate = await this.$validator.validateAll();
            if (!validate || this.$validator.errors.any() || this.$props.formData.initiation_ids.length < 1) {
                this.$emit("inValid");
            } else {
                this.$emit("valid");
            }
        },
        parsingOffice(office) {
            const index = this.$props.formData.initiation_ids.findIndex(d => d === office);

            if (index > -1) {
                this.$props.formData.initiation_ids.splice(index, 1);
            } else {
                this.$props.formData.initiation_ids.push(office);
            }
        }
    }
};
</script>
